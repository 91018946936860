<template>
<div>
<v-select
  :items="brothers.data"
  v-model="brother"
  item-text="name"
  item-value="id"
  label="Broder"
  solo
  @click="loadBrothers()"
></v-select>
<v-text-field
    label="Mötesnummer"
    solo
    v-model="meeting"
    required
    class="centered-input"
    type="number"
    ></v-text-field>
  <v-card
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
    v-for="x in products.data"
    v-bind:key="x.id"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      height="250"
      :src="`data:image/png;base64,${x.image_1920}`"
    ></v-img>

    <v-card-title>{{x.name}} - {{x.list_price}}€ </v-card-title>

    <v-card-text>
      <v-row
        class="mx-0"
      >
    <div v-if="x.description_sale">{{x.description_sale}}</div>
      </v-row>



    </v-card-text>

    <v-card-actions>
        <v-container>
            <v-row>
                <v-col
                cols="4"
                sm="3">
                    <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="removeQty(x)"
                    >
                    <v-icon dark>
                        mdi-minus
                    </v-icon>
                    </v-btn>
                </v-col>

                <v-col
                cols="4"
                sm="6">
                <div
                v-for="c in cart"
                v-bind:key="c.product_id"
                >
                  <h2 class="centered-input" v-if="x.id == c.product_id">
                    {{c.product_uom_qty}}
                  </h2>
                </div>
                </v-col>
                <v-col
                cols="4"
                sm="3">
                    <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="addQty(x)"
                    >
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>


    </v-card-actions>
  </v-card>
  <div v-if="meeting && brother">
      <v-btn
      depressed
      color="primary"
      @click="saveDrinks"
    >
      Spara
    </v-btn>
</div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      top
      right
      shaped
      color="deep-green accent-4"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          top
          v-bind="attrs"
          @click="snackbar = false"
        >
          Stäng
        </v-btn>
      </template>
    </v-snackbar>
</div>
</template>

<style scoped>
    .centered-input {
      text-align: center;
      font-weight: lighter;
    }
</style>

<script>


import axios from 'axios';
  export default {
    data: () => ({
      loading: false,
      selection: 1,
      products: '',
      meeting: '',
      brothers: '',
      brother: [],
      cart: [],
      qty: [],
      snackbar: false,
      text: 'SPERMA! Dina drycker sparade!',
      timeout: 3000,
    }),
    methods: {
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 1000)
      },
      async loadProducts() {
        axios 
        .get('https://api.rt-90.fi/api/v1/products')
        .then(response => (this.products = response))
      },
      async loadBrothers() {
        axios 
        .get('https://api.rt-90.fi/api/v1/brothers')
        .then(response => (this.brothers = response))
        this.brothers.forEach(x => {
          this.brother.push(x.name)
          console.log(x.name)
        });
      },
      addQty(itemToAdd){
        let itemInCart = this.cart.filter(x => x.product_id ===itemToAdd.id);
        let isItemInCart = itemInCart.length > 0;
        if (isItemInCart === false) {
                this.cart.push(
				{product_id: itemToAdd.id, product_uom_qty: 1}
			);
        }
        else{
            itemInCart[0].product_uom_qty += 1;
            }
      },
      removeQty(itemToRemove){
        let itemInCart = this.cart.filter(x => x.product_id ===itemToRemove.id);
        let isItemInCart = itemInCart.length > 0;

        if (isItemInCart === true) {
            if (itemInCart[0].product_uom_qty > 0){
                itemInCart[0].product_uom_qty -= 1;
            }
        }
        else{
            console.log('Negative amounts of products not allowed!')
            }
			

      },
      saveDrinks(){
         axios.post('https://api.rt-90.fi/api/v1/orders', {
            email: 'simon@erpware.fi',
            id: this.brother,
            state: 'sale',
            reference: this.meeting,
            products: this.cart

        })
        .then(function (response) {
            console.log(response);
            this.text = "Hell yeah!!"
            this.snackbar = true;
        })
        .catch(function (error) {
            console.log(error);
            this.text = "Error";
            this.snackbar = true;
        });
        this.cart = [];
        this.meeting = null;
        alert('Dryckerna sparade')
      }
    },
    mounted () {
        this.loadProducts();
    }
  }
</script>