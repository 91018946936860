<template>
<v-app>
  <div class="home disable-dbl-tap-zoom">
    <router-link to="/">
    <img alt="RT-90 logo" width="120" src="../assets/rt-logo.png">
    </router-link>
    <Title/>
    <v-container>
      <v-row>
        <v-col>
          <Drinks />
        </v-col>
      </v-row>

    </v-container>
    
    
  </div>

  </v-app>
</template>

<style>
.disable-dbl-tap-zoom {
  touch-action: manipulation;
}
input[type="button"]{
  touch-action: manipulation;
}
</style>

<script>
// @ is an alias to /src
import Title from '@/components/Title.vue'
import Drinks from '@/components/Drinks.vue'
export default {
  name: 'Home',
  components: {
    Title, Drinks
  }
}
</script>
